import { AxiosRequestConfig } from 'axios'
import { APIS } from 'constants/apis'

import axios from '../../index'
import { attributes, economic_analysis } from '../getTransaction/types'
import {
  addBalanceSheetPayload,
  addFinancialStructurePayload,
  editBalanceSheetResponse,
} from './types/addFincancialStructure.type'
import { AddFunctionalAttributesProps } from './types/addFunctionalAttributes.types'
import { QuarterlyAnalysis } from './types/addQuartelyAnalysis.types'
import { attributeTransactionDescriptionResponse } from './types/attributreTransactionDescription'
import { ComplianceRegionPayloadProps, TransferPricingMethods } from './types/complianceRegion.type'
import { updateEconomicAnalysisPayload, updateRejectionMatrixPayload } from './types/economicAnalysis.types'
import { editEconomicAnalysisPayloadProps } from './types/editEconomicAnalysis.types'
import { FunctionalAttributes, FunctionalProfileAttributeResponse } from './types/functionalProfileAttributes.types'
import { GetConclusionRequestPayload } from './types/getConclusion.type'
import { LegalEntitiesTypeResponse } from './types/legalEntities.type'
import { TransactionFunctionProfileTypeResponse } from './types/transactionFunctionProfile.type'
import { TransactionType } from './types/transactionType'
import { UpdateAttributeProps } from './types/updateAttribute.type'
import { updateEconomicAnalysisTransactionPayloadProps } from './types/updateEconomicAnalysisTransaction.types'
import {
  CreateTransactionResponse,
  DeleteTransactionFilesProps,
  UploadUserFileProps,
} from './types/UploadUserFileProps'

export const getTransactionType = () =>
  axios.get<TransactionType[]>(`/api/v1/org/{organization}/transaction/transaction_type/`).then(res => res.data)

export const getLegalEntity = ({ year }: { year?: number }) => {
  const params: { [key: string]: number | string | [] } = {}
  if (year) {
    params.year = year
  }
  return axios
    .get<LegalEntitiesTypeResponse[]>(`/api/v1/org/{organization}/legal_entity/legal_entity/`, {
      params,
    })
    .then(res => res.data)
}

export const getTransactionFunctionalProfile = (id?: number) =>
  axios
    .get<TransactionFunctionProfileTypeResponse[]>(
      `/api/v1/org/{organization}/transaction/functional_profile/?transaction_type=${id}`
    )
    .then(res => res.data)

export async function createTransaction(variables: UploadUserFileProps) {
  const {
    file,
    currency,
    is_apa,
    apa_description = '',
    year,
    source_characterisation,
    target_characterisation,
    primary_entity_id,
    is_primary_le_source,
    individual_transactions,
    functional_profile_id,
    transaction_name,
    transaction_type,
    transaction_description,
    benchmarking_id,
    associated_entity_type,
    primary_entity_type,
    associated_entities_group_name,
    fx_notes,
    compliance_region,
  } = variables

  const formData = new FormData()

  if (file) {
    formData.append('intercompany_agreement', file, file?.name)
  }

  if (transaction_type) formData.append('transaction_type', transaction_type)
  if (currency) formData.append('currency', currency)
  if (compliance_region) formData.append('compliance_region', compliance_region)
  if (typeof is_apa === 'boolean') formData.append('is_apa', is_apa.valueOf().toString())
  formData.append('apa_description', is_apa ? apa_description : '')
  if (year) formData.append('year', year.toString())
  if (source_characterisation) formData.append('source_characterisation', source_characterisation)
  if (target_characterisation) formData.append('target_characterisation', target_characterisation)
  if (primary_entity_id) formData.append('primary_entity_id', primary_entity_id.toString())
  if (typeof is_primary_le_source === 'boolean')
    formData.append('is_primary_le_source', is_primary_le_source.valueOf().toString())
  if (individual_transactions) formData.append('individual_transactions', JSON.stringify(individual_transactions))
  if (functional_profile_id) formData.append('functional_profile_id', functional_profile_id.toString())
  if (transaction_name) formData.append('name', transaction_name)
  if (benchmarking_id) formData.append('benchmarking_id', benchmarking_id.toString())
  if (transaction_description) formData.append('transaction_description', transaction_description)
  if (primary_entity_type) formData.append('primary_entity_type', primary_entity_type)
  if (associated_entity_type) formData.append('associated_entity_type', associated_entity_type)
  if (typeof associated_entities_group_name === 'string')
    formData.append('associated_entities_group_name', associated_entities_group_name)
  if (typeof fx_notes === 'string') formData.append('fx_notes', fx_notes)

  const axiosResponse = await axios.post<CreateTransactionResponse>(
    '/api/v1/org/{organization}/transaction/transactions/',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return axiosResponse
}

export const getAllFunctionalProfileAttribute = ({
  attributeType,
  id,
  exclude = false,
}: {
  attributeType: string
  id: number
  exclude?: boolean
}) => {
  return axios.get<FunctionalProfileAttributeResponse>(
    `/api/v1/org/{organization}/transaction/attributes/get_functional_profile_attributes/?functional_profile=${id}&attribute_type=${attributeType}&exclude=${exclude}`
  )
}

export const getTransactionAttributesByFilters = ({
  transactionId,
  attributeType,
}: {
  transactionId?: number
  attributeType?: string
}) => {
  return axios
    .get<attributes[]>(APIS.TRANSACTIONS.GET_TRANSACTION_ATTRIBUTES, {
      params: {
        attribute__attribute_type: attributeType,
        transaction: transactionId,
      },
    })
    .then(res => res.data)
}

export const getAttributeDataById = ({ attributeId }: { attributeId: number }) => {
  return axios
    .get<attributes>(`/api/v1/org/{organization}/transaction/transaction_attributes/${attributeId}`)
    .then(res => res.data)
}

export const updateAttribute = (payload: UpdateAttributeProps) => {
  return axios[payload.id ? 'patch' : 'post'](
    `/api/v1/org/{organization}/transaction/transaction_attributes/${payload.id ? `${payload.id}/` : ''}`,
    payload
  )
}

export const deleteAttribute = (payload: { id: number }) => {
  return axios.delete(`/api/v1/org/{organization}/transaction/transaction_attributes/${payload.id}`)
}

export const updateAttributeOrder = ({
  attributes,
  attribute_type,
  transaction_id,
}: {
  attributes: number[]
  attribute_type: string
  transaction_id: number
}) => {
  return axios.patch(`/api/v1/org/{organization}/transaction/transaction_attributes/update_attributes_order/`, {
    attributes: attributes,
    attribute_type: attribute_type,
    transaction: transaction_id,
  })
}

export const getTransferPricingMethods = () => {
  return axios.get<TransferPricingMethods[]>('/api/v1/org/{organization}/transaction/tp_method/').then(res => res.data)
}

export const getComplianceRegion = ({ complianceRegion, transactionType }: ComplianceRegionPayloadProps) => {
  return axios.get<TransferPricingMethods[]>(
    `/api/v1/org/{organization}/transaction/tp_method/?compliance_region=${complianceRegion}&transaction_type_or_common=${transactionType}`
  )
}

export const updateEconomicAnalysis = (payload: updateEconomicAnalysisPayload) => {
  return axios.post(`/api/v1/org/{organization}/transaction/economic_analysis/`, payload)
}

export const getRejectionMatrixDefaultDescription = ({
  tpMethodId,
  transactionId,
  template_name,
}: {
  tpMethodId: string
  transactionId: number
  template_name: string
}) => {
  return axios
    .get<{ description: string }>(
      `/api/v1/org/{organization}/transaction/transactions/${transactionId}/get_rejection_matrix_default_description/`,
      { params: { tp_method: tpMethodId, template_name } }
    )
    .then(res => res.data)
}

export const updateRejectionMatrix = (payload: updateRejectionMatrixPayload) => {
  return axios.put(
    `/api/v1/org/{organization}/transaction/economic_analysis/${payload.id}/update_rejection_matrix/`,
    payload.data
  )
}

export const editFinancialStructure = (payload: addFinancialStructurePayload) => {
  return axios.put(
    `/api/v1/org/{organization}/transaction/economic_analysis/${payload.id}/edit_financial_structure/`,
    payload.addFinancialStructurePayload
  )
}
export const editBalanceSheet = (payload: addBalanceSheetPayload) => {
  return axios
    .put<editBalanceSheetResponse>(
      `/api/v1/org/{organization}/transaction/economic_analysis/${payload.id}/edit_balance_sheet/`,
      payload.addBalanceSheetPayload
    )
    .then(res => res.data)
}

export const economicAnalysisTransaction = (id: number) => {
  return axios.get<economic_analysis[]>(`/api/v1/org/{organization}/transaction/economic_analysis/?transaction=${id}`)
}

export const updateEconomicAnalysisTransaction = (payload: updateEconomicAnalysisTransactionPayloadProps) => {
  return axios.patch(`/api/v1/org/{organization}/transaction/economic_analysis/${payload.id}/`, {
    ...payload,
  })
}

export async function editEconomicAnalysis(variables: editEconomicAnalysisPayloadProps) {
  const {
    transactionId,
    transfer_pricing_method,
    pli_method,
    financial_data_file,
    economicAnalysisId,
    financial_data_file_name,
    gaap_type,
    tested_party_currency,
    testing_policy_description,
    policy_rate,
    testing_methodology,
  } = variables

  const payload = {
    transactionId: transactionId.toString(),
    transfer_pricing_method: transfer_pricing_method,
    pli_method: pli_method,
    financial_data_file_name: financial_data_file_name || '',
    financial_data_file: financial_data_file,
    gaap_type,
    tested_party_currency,
    policy_rate,
    testing_methodology,
    testing_policy_description,
  }

  const apiOptions: AxiosRequestConfig = { headers: {} }

  if (financial_data_file instanceof File || testing_policy_description instanceof File) {
    if (apiOptions.headers) {
      apiOptions.headers['Content-Type'] = 'multipart/form-data'
    }
  }

  const axiosResponse = await axios.patch<CreateTransactionResponse>(
    `/api/v1/org/{organization}/transaction/economic_analysis/${economicAnalysisId}/`,
    payload,
    apiOptions
  )
  return axiosResponse
}

export const addQuarterlyAnalysis = (payload: QuarterlyAnalysis) => {
  return axios.post<QuarterlyAnalysis[]>(
    `/api/v1/org/{organization}/transaction/economic_analysis/${payload.economicAnalysisId}/add_quarterly_analysis/`,
    payload.quartelyAnalysis
  )
}

export const editQuarterlyAnalysis = (payload: QuarterlyAnalysis) => {
  return axios.put<QuarterlyAnalysis[]>(
    `/api/v1/org/{organization}/transaction/economic_analysis/${payload.economicAnalysisId}/edit_quarterly_analysis/`,
    payload.quartelyAnalysis
  )
}

export const deleteTransactionHTMLFiles = async ({ id, ...variables }: DeleteTransactionFilesProps) => {
  const axiosResponse = await axios.patch<CreateTransactionResponse>(
    `/api/v1/org/{organization}/transaction/transactions/${id}/`,
    variables
  )
  return axiosResponse
}

export const editTransaction = async (variables: UploadUserFileProps) => {
  const {
    file,
    currency,
    is_apa,
    apa_description,
    year,
    source_characterisation,
    target_characterisation,
    primary_entity_id,
    is_primary_le_source,
    individual_transactions,
    functional_profile_id,
    transaction_name,
    transaction_description,
    intercompany_flows,
    intercompany_flows_description,
    id,
    transaction_type,
    benchmarking_id,
    primary_entity_type,
    associated_entity_type,
    conclusion,
    fx_notes,
    associated_entities_group_name,
    intercompany_agreement_id,
    mirror_functional_analysis_conclusion,
    mirror_transaction_description,
    compliance_region,
  } = variables

  const formData = new FormData()

  if (file) {
    formData.append('intercompany_agreement', file, file?.name)
  }

  if (transaction_type) formData.append('transaction_type', transaction_type)
  if (currency) formData.append('currency', currency)
  if (compliance_region) formData.append('compliance_region', compliance_region)
  if (typeof is_apa === 'boolean') formData.append('is_apa', is_apa.valueOf().toString())
  if (typeof apa_description === 'string') formData.append('apa_description', is_apa ? apa_description : '')
  if (year) formData.append('year', year.toString())
  if (source_characterisation) formData.append('source_characterisation', source_characterisation)
  if (target_characterisation) formData.append('target_characterisation', target_characterisation)
  if (primary_entity_id) formData.append('primary_entity_id', primary_entity_id.toString())
  if (typeof is_primary_le_source === 'boolean')
    formData.append('is_primary_le_source', is_primary_le_source.valueOf().toString())
  if (individual_transactions) formData.append('individual_transactions', JSON.stringify(individual_transactions))
  if (functional_profile_id) formData.append('functional_profile_id', functional_profile_id.toString())
  if (transaction_name) formData.append('name', transaction_name)
  if (transaction_description) formData.append('transaction_description', transaction_description)
  if (intercompany_flows_description) formData.append('intercompany_flows_description', intercompany_flows_description)
  if (intercompany_flows) formData.append('intercompany_flows', intercompany_flows)
  if (benchmarking_id) {
    formData.append('benchmarking_id', benchmarking_id.toString())
  }
  if (primary_entity_type) formData.append('primary_entity_type', primary_entity_type)
  if (associated_entity_type) formData.append('associated_entity_type', associated_entity_type)
  if (typeof conclusion === 'string') formData.append('conclusion', conclusion)
  if (typeof fx_notes === 'string') formData.append('fx_notes', fx_notes)
  if (typeof associated_entities_group_name === 'string') {
    formData.append('associated_entities_group_name', associated_entities_group_name)
  }
  if (intercompany_agreement_id) formData.append('intercompany_agreement_id', intercompany_agreement_id.toString())
  if (mirror_functional_analysis_conclusion) {
    formData.append('mirror_functional_analysis_conclusion', mirror_functional_analysis_conclusion.toString())
  }
  if (mirror_transaction_description) {
    formData.append('mirror_transaction_description', mirror_transaction_description.toString())
  }
  const axiosResponse = await axios.patch<CreateTransactionResponse>(
    `/api/v1/org/{organization}/transaction/transactions/${id}/`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return axiosResponse
}

export const addFunctionalAttributes = (payload: AddFunctionalAttributesProps) => {
  return axios.patch<FunctionalAttributes>(
    `/api/v1/org/{organization}/transaction/attributes/add_attribute_to_functional_profile/`,
    payload
  )
}

export const getAttributeTransactionDescription = (id: number) => {
  return axios
    .get<attributeTransactionDescriptionResponse>(`/api/v1/org/{organization}/transaction/attributes/${id}/`)
    .then(res => res.data)
}

export const getConclusionByType = ({ conclusionType, transactionId, template_name }: GetConclusionRequestPayload) => {
  return axios
    .get<{ conclusion: string }>(APIS.TRANSACTIONS.GET_DEFAULT_CONCLUSION, {
      params: {
        transaction: transactionId,
        template_name,
        conclusion_type: conclusionType,
      },
    })
    .then(res => res.data)
}

export const generateAiConclusionByType = ({
  conclusionType,
  transactionId,
  template_name,
}: GetConclusionRequestPayload) => {
  return axios
    .get<{ recommendations: string[] }>(APIS.TRANSACTIONS.GET_AI_CONCLUSION, {
      params: {
        transaction: transactionId,
        template_name: template_name,
        conclusion_type: conclusionType,
      },
    })
    .then(res => res.data)
}

export const updateAttributeName = ({ id, attributeName }: { id: number; attributeName: string }) => {
  return axios
    .patch<attributeTransactionDescriptionResponse>(`/api/v1/org/{organization}/transaction/attributes/${id}/`, {
      name: attributeName,
    })
    .then(res => res.data)
}
